<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section pt-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="support-image">
                    <img src="assets/img/support.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-tab">
                    <div class="section-title">
                    <h2><span>About Bomupu Technologies</span></h2>
                    <div class="bar"></div>
                    </div>
                    
                    <h3> 2008 the year of foundation</h3>
                    <div class="tab about-list-tab">
                        <ul class="tabs">
                            <li><a href="#">About </a></li>
                            <li><a href="#">Our Vision</a></li>
                            <li><a href="#">Our Core Values</a></li>
                        </ul>
                        <div class="tab_content">
                            <div class="tabs_item">
                                <div class="text">
                                    <p>Bopumu Technologies was founded in 2008 with a primary focus of servicing the ICT market in South Africa. Our status as an Emerging Micro Enterprise affords us the opportunity to work with small to medium enterprises. We work with national and provincial government entities by largely focusing on taking a consultative solution approach – we collaborate with our partners to understand our customers’ needs both from a technology and business perspective.</p>
                                </div>
                               
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <p>Successful businesses know that looking to the future is vital. Future trends, future risks, future customers… future tech. We take the time to personally understand each and every organization we work with. From culture to commercial targets and operational challenges.</p>
                                </div>
                              
                            </div>
                            <div class="tabs_item">
                                <div class="text">
                                    <p>Our people are BIG fans of cloud technology, and love finding new ways to help our customers unlock its potential.</p>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
           <br/>   <br/>
            <h2>Frequently <span>Asked</span> Questions</h2>
           
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <a data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">... <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseOne" class="collapse show" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" role="tab" id="headingTwo">
                            <a class="collapsed" data-bs-toggle="collapse" data-bs-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">... <i class='bx bx-chevron-down'></i></a>
                        </div>
                        <div id="collapseTwo" class="collapse" role="tabpanel" data-bs-parent="#accordionEx">
                            <div class="card-body">
                                <p>.</p>
                            </div>
                        </div>
                    </div>
                   
                 
                   
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

