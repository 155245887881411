<app-navbar-one></app-navbar-one>

<div class="main-banner-area-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <h2 class="text-primary"><span>Enterprise Infrastructure, Cloud, Cyber, Software & Digital Development</span></h2>
                            <p>IT strategy and planning, IT infrastructure design and implementation, Software development and customization, IT project management, Cybersecurity and risk management,
                                Data management and analytics & IT support and maintenance  </p>
                            <div class="banner-btn">
                             
                                <a routerLink="/contact" class="default-btn">LET'S TALK</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/01.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/7.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image">
                            <img src="assets/img/31.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="0.6s" alt="image">
                          
                            <img src="assets/img/home-two/5.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="0.6s" alt="image">
                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape"><img src="assets/img/home-two/cloud.png" alt="image"></div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</div>

<section class="work-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="work-content">
                    <h3><span>Bopumu Technologies</span>  <span></span> </h3>
                    <p>Bopumu Technologies was founded in 2008 with a primary focus of servicing the ICT market in South Africa. Our status as an Emerging Micro Enterprise affords us the opportunity to work with small to medium enterprises. We work with national and provincial government entities by largely focusing on taking a consultative solution approach – we collaborate with our partners to understand our customers’ needs both from a technology and business perspective.</p>
                    <h3><span>--- ---</span>  <span></span> </h3>
                    <p>Successful businesses know that looking to the future is vital. Future trends, future risks, future customers… future tech. We take the time to personally understand each and every organization we work with. From culture to commercial targets and operational challenges.</p>
                    <h3><span>--- ---</span>  <span></span> </h3>
                    <p>Our people are BIG fans of cloud technology, and love finding new ways to help our customers unlock its potential.</p>
                   
                    <p> <a routerLink="/services" class="read-btn">Read More</a></p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="support-image">
                    <img src="assets/img/dev.png" alt="image">
                    <a href="https://www.youtube.com/watch?v=YymWhauqjoA" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>




<section class="services-section bg-background pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>Sailor made for you.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-it"></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>We offer advisory, strategic, and technical services to our clients optimize their IT infrastructure, systems, and processes.<br/><br/></p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Networking & Access Control and CCTV</h3>
                    <p>Managing your network should be one of your highest priorities. Everything you do at work is built upon this fundamental layer.</p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>IT strategy & planning</h3>
                    <p>Assisting clients in developing a strategic roadmap for their IT initiatives, aligning technology with business goals, and identifying areas for improvement..</p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>App Development</h3>
                    <p>We develop custom software solutions tailored to the client's specific requirements, as well as integrating and customizing existing software applications..</p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Cybersecurity and risk management </h3>
                    <p>We assess and mitigate cybersecurity risks, implementing security measures and protocols, and providing ongoing monitoring and support to protect against potential threats.</p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Enterprise Infrastructure</h3>
                    <p>Modernize your IT Infrastructure with our offering of top tier brands with the latest cutting edge technology.<br/><br/></p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>Solar installations</h3>
                    <p>Certified solar installers, we are qualified to set up and install complete solar systems and integrate inverters and battery banks.<br/><br/></p>
                    <a routerLink="/services" class="read-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="support-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/support.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-internet"></i>
                    </div>
                    <h3>Thinking big</h3>
                    <p>After digging deep to understand holistically your challenges and business objectives, we chart your technology path that will keep your business future-proof.</p>
                </div>
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Creating value fast</h3>
                    <p>We then build an MVP, testing the first use cases and collecting feedback. Along the way, we introduce enhancements to align business deliverables and add new use cases.</p>
                </div>
                
                <div class="support-content">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Innovating at scale</h3>
                    <p>We are expanding the functionality of your system, keeping it flexible on the tech stack, hugely adaptable to humans, and easily scalable to evolve along with your business growth.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overview-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-edit"></i>
                    </div>
                    <h3>Sketch</h3>
                  
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-thinking"></i>
                    </div>
                    <h3>Design</h3>
                  
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-analysis"></i>
                    </div>
                    <h3>Test</h3>
                  
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="overview-content">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>
                    <h3>Develop</h3>
                  
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2><span>WHY US</span> </h2>
            <p>WHAT SETS US APART:</p>
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-shared-folder"></i>
                    </div>
                    <h3> <span>PROCESS-ORIENTED</span> </h3>
                    <p>We follow battle-tested, unique processes for everything that we do, ensuring high-quality and efficient.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>DIGITAL ACCELERATION WITH A LEAN MINDSETData Management</h3>
                    <p>We focus on doing the right things to fuel your growth, nothing more, nothing less.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-quality"></i>
                    </div>
                    <h3>FULL TRANSPARENCY</h3>
                    <p>We provide transparent and clear communication, project management.</p>
                </div>
                <div class="choose-text">
                    <div class="icon">
                        <i class="flaticon-target"></i>
                    </div>
                    <h3>TECHNICAL EXCELLENCY</h3>
                    <p>Our agile engineering practices will bring software craftsmanship, quality, and consistency to the table, all in a flexible state to guarantee ever-changing requirements.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2><span>Our Clients</span> </h2>
           
           
        </div>
        <div class="row align-items-center">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/higheredu.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/roads_and_transport.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/nkangala.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/magalieswater.png" alt="" style="height:100px">
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
</section>


<section class="choose-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2><span>Our Partners</span> </h2>
           
           
        </div>
        <div class="row align-items-center">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/hp.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/veeam.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/lenovo.png" alt="" style="height:100px">
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="single-services-box">
                        <img class="pro-logo" src="assets/img/dell.png" alt="" style="height:100px">
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
</section>





<section class="clients-section bg-background ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
          
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>.</p>
                <div class="clients-content">
                    <h3>...</h3>
                    <span>..............</span>
                </div>
            </div>
           
          
           
        </div>
    </div>
</section>

<div class="partner-section ptb-50">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            
        </div>
    </div>
</div>