<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/dev.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3><span>Software Development</span></h3>
                    <div class="bar"></div>
                  
                    <p>
                        Web, Mobile, Windows Application Development, Cloud Solutions<br/><br/>
                        Business Applications drive productivity in today’s ever changing world. Application development (app dev) is a key consideration for any business growth. Whether it’s a smaller supportive app, a whole back-end system or an innovative customer-facing solution, we build what you need to maintain unparalleled efficiency in the market.
                        <br/><br/>
                        Innovative application development requires the best building blocks. Our tech stack enables you to improve your application life-cycle through modern agile development. This means you get a constantly adjustable and future proof application that's tailored to assist you to outsmart the future.
                        
                        </p>   <div class="audience-btn">
                      
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3> <span>IT Consultancy</span> </h3>
                        <div class="bar"></div>
                        <p class="align-items-left"> Bopumu has a highly certified team of subject matter experts in the various IT Services it offers including infrastructure engineers, software engineers, network engineers, project managers and change specialists who are all dedicated to taking care of the entire IT journey – leaving your team to focus on doing what they do best.
                            <br/>
                            These specialist services allow us to be the perfect consulting partner for most of our customers’ needs.
                            <br/>
                            Start a conversation with us to understand what your business needs are.<br/><br/></p>
                    </div>
                   
                   
                  
                 
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="design-image">
                    <img src="assets/img/img45.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="design-image">
                    <img src="assets/img/app.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="design-content">
                    <h3> <span>Cyber security</span></h3>
                    <div class="bar"></div>
                    <p>Business growth shouldn’t come at the expense of security. That’s why all the technologies we work with offer multi-layered protection.
                        <br/><br/>
                                                Bopumu also offers its customers various class leading security products. Peace of mind for businesses set them up for succes.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>


<section class="audience-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="audience-image">
                    <img src="assets/img/31.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="audience-content">
                    <h3><span>Enterprise Infrastructure</span></h3>
                    <div class="bar"></div>
                  
                    <p>
                        <p>Modernize your IT Infrastructure with our offering of top tier brands with the latest cutting edge technology.
                            <br/>
                            And if your organization is looking for future proof solutions stop spending time and money maintaining out of date on-premise hardware and go serverless for a scalable, powerful and robust IT infrastructure.
                            <br/>
                            Bopumu will help you migrate your workloads.<br/>
                            From proof of concept to meticulous deployment, project handover and beyond, you’re guaranteed a bespoke, end-to-end experience when you work with us. We fully immerse ourselves in your business to understand your unique challenges and ambitions. Only then can we deliver a fully future-proof infrastructure solution that’s tailored to you</p>
                         <div class="audience-btn">
                      
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3> <span>Networking</span> </h3>
                        <div class="bar"></div>
                        <p>Managing your network should be one of your highest priorities. Everything you do at work is built upon this fundamental layer. A problem in this layer with a cable, a UPS, a connection, or any data transfer bottleneck will disrupt your business – potentially critically.
                            <br/>
                            How long would it take you to replace and rewire an entire floor’s network infrastructure? Source and procure market-leading UPS? Rewire multiple access points? How many of your integrated systems would be down during this process? Telephones? Security? Building Management?
                            <br/>
                            At Bopumu we help you to build a robust, scalable network that supports your organization, providing the foundation for access to services, enhancing productivity, enabling efficiency, and delivering the experience to your end users that business requires.
                            <br/>
                            We partner with leading network vendors to deliver network solutions that accelerate innovation, reduce cost and complexity, and enhance security – from the data centre through to the mobile worker</p>
                       
                    </div>
                   
                   
                  
                 
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="audience-image">
                    <img src="assets/img/25.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="design-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="design-image">
                    <img src="assets/img/21.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="design-content">
                    <h3> <span>Access Control and CCTV</span></h3>
                    <div class="bar"></div>
                     
                    <p> 
                        Bopumu specializes in a range of security and surveillance systems such as CCTV Analogue, IP and Hybrid Systems, Access Control, PA Systems, Fire Detection, Off Site Reviewing, Off Site Monitoring.
                        <br/>
                        At Bopumu we understand that it is imperative for companies of all sizes to have visibility into and awareness of possible dangerous activities within their business.
                        <br/> <br/> Our portfolio offers:
                        <br/>
                        • Time and Attendance • Biometrics • Proximity • Turnstiles • Revolving Doors • IP Cameras <br/>
                        • Thermal Cameras • HD over COAX CCTV Systems • Control Room Consoles • Conventional Fire Detection Systems • Addressable Fire Detection Systems
                        </p>
   </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>


<section class="solutions-section pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-content-area">
                    <div class="solutions-content">
                        <h3> <span>Solar installations</span> </h3>
                        <div class="bar"></div>
                        <p>Bopumu provides solar power solutions from system
                            design to installation and after sales support.
                            <br/>
                            We are certified solar installers ,  qualified to set up and install complete solar systems and integrate inverters and battery banks
                            <br/>
                            
                    </div>
                   
                   
                  
                 
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pr-0">
                <div class="audience-image">
                    <img src="assets/img/612.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>



<section class="faq-section pb-100">
    <div class="container">
        <div class="section-title">
           
            <div class="bar"></div>
        </div>
        <div class="row align-items-center">
            
        </div>
    </div>
</section>